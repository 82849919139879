import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header
    style={{
      // backgroundColor: '#0065ff',
      backgroundColor: "",
      // left: 0,
      // right: 0,
      // position: 'fixed',
      // zIndex: 10,
    }}
    className="mb3"
  >
    <div className="flex justify-between">
      <div className="pa3 pa5-ns">
        <Link className="white dim fw6 lh-title" to="/">
          {siteTitle}
        </Link>
      </div>
      {/* <nav className="flex"> */}
      {/*   <NavLink to="/">Home</NavLink> */}
      {/*   <NavLink to="/services">Services</NavLink> */}
      {/*   <NavLink to="/work">Work</NavLink> */}
      {/*   <NavLink to="/about">About</NavLink> */}
      {/* </nav> */}
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
